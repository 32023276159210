/* 
* 
* THIS IS A SMALL BONUS FOR ALL CURIOUS PEOPLE :) 
* Just add class .animated and .pulse, .rotateIn, .bounce, .swing or .tada to you HTML element with icons. You may find other great css animations here: http://coveloping.com/tools/css-animation-generator 
* 
*/
@if $glyphicons-halflings-include-bonus == true { 
  .animated { 
    -webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
    -webkit-animation-timing-function: ease-in-out; 
    animation-timing-function: ease-in-out; 
    animation-iteration-count:infinite; 
    -webkit-animation-iteration-count:infinite; 
  } 
  
  @-webkit-keyframes pulse { 
    0% { -webkit-transform: scale(1); } 
    50% { -webkit-transform: scale(1.1); } 
    100% { -webkit-transform: scale(1); } 
  } 
  @keyframes pulse { 
    0% { transform: scale(1); } 
    50% { transform: scale(1.1); } 
    100% { transform: scale(1); } 
  } 
  .pulse { 
    -webkit-animation-name: pulse; 
    animation-name: pulse; 
  }
  
  @-webkit-keyframes rotateIn { 
    0% { 
        -webkit-transform-origin: center center; 
        -webkit-transform: rotate(-200deg); 
        opacity: 0; 
    } 
    100% { 
        -webkit-transform-origin: center center; 
        -webkit-transform: rotate(0); 
        opacity: 1; 
    } 
  } 
  @keyframes rotateIn { 
    0% { 
        transform-origin: center center; 
        transform: rotate(-200deg); 
        opacity: 0; 
    } 
    100% { 
        transform-origin: center center; 
        transform: rotate(0); 
        opacity: 1; 
    } 
  } 
  .rotateIn { 
    -webkit-animation-name: rotateIn; 
    animation-name: rotateIn; 
  }
  
  @-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);} 
  } 
  
  @keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
  } 
  
  .bounce { 
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
  }
  
  @-webkit-keyframes swing { 
    20%, 40%, 60%, 80%, 100% { -webkit-transform-origin: top center; } 
    20% { -webkit-transform: rotate(15deg); } 
    40% { -webkit-transform: rotate(-10deg); } 
    60% { -webkit-transform: rotate(5deg); } 
    80% { -webkit-transform: rotate(-5deg); } 
    100% { -webkit-transform: rotate(0deg); } 
  } 
  @keyframes swing { 
    20% { transform: rotate(15deg); } 
    40% { transform: rotate(-10deg); } 
    60% { transform: rotate(5deg); } 
    80% { transform: rotate(-5deg); } 
    100% { transform: rotate(0deg); } 
  } 
  .swing { 
    -webkit-transform-origin: top center; 
    transform-origin: top center; 
    -webkit-animation-name: swing; 
    animation-name: swing; 
  }
  
  @-webkit-keyframes tada { 
    0% {-webkit-transform: scale(1);} 
    10%, 20% {-webkit-transform: scale(0.9) rotate(-3deg);} 
    30%, 50%, 70%, 90% {-webkit-transform: scale(1.1) rotate(3deg);} 
    40%, 60%, 80% {-webkit-transform: scale(1.1) rotate(-3deg);} 
    100% {-webkit-transform: scale(1) rotate(0);} 
  } 
  @keyframes tada { 
    0% {transform: scale(1);} 
    10%, 20% {transform: scale(0.9) rotate(-3deg);} 
    30%, 50%, 70%, 90% {transform: scale(1.1) rotate(3deg);} 
    40%, 60%, 80% {transform: scale(1.1) rotate(-3deg);} 
    100% {transform: scale(1) rotate(0);} 
  } 
  .tada { 
    -webkit-animation-name: tada; 
    animation-name: tada; 
  }
}
